<template>
  <div class="portal_login flex-center">
    <div class="login_center posi-rel">
      <!--  左侧登录区域-->
      <div class="login_center_left">
        <div class="flex-center width-100">
          <img src="../../assets/login-5.png" class="title_img" alt="" />
        </div>
        <div class="title_text">
          Already Have An Account?
          <span class="main-color pointer" @click="navTo('/login')">Login</span>
        </div>
        <!--用户名输入框 -->
        <div class="login_fill width-100">
          <div class="login_fill_input width-100">
            <el-input v-model="query.nickname" clearable placeholder="NickName">
            </el-input>
          </div>
        </div>
        <!-- email输入框 -->
        <div class="login_fill space-between width-100">
          <div class="login_fill_input login_fill_input_one">
            <el-input v-model="query.email" clearable placeholder="Email"> </el-input>
          </div>
          <div class="login_pin flex-center pointer" @click="codeClick">{{ second }}</div>
        </div>
        <!-- 验证码输入框 -->
        <div class="login_fill width-100">
          <div class="login_fill_input width-100">
            <el-input v-model="query.code" clearable placeholder="Authentication Code">
            </el-input>
          </div>
        </div>
        <!-- 登录密码输入框 -->
        <div class="login_fill width-100">
          <div class="login_fill_input width-100">
            <el-input v-model="query.password" type="password" clearable placeholder="Login Password">
            </el-input>
          </div>
        </div>
        <!-- 确认密码输入框 -->
        <div class="login_fill width-100">
          <div class="login_fill_input width-100">
            <el-input v-model="query.repwd" clearable type="password" placeholder="Confirm Login Password">
            </el-input>
          </div>
        </div>
        <!-- 邀请码输入框 -->
        <div class="login_fill width-100">
          <div class="login_fill_input width-100">
            <el-input v-model="query.invite_code" clearable placeholder="Manager Code (optional)">
            </el-input>
          </div>
        </div>
        <!-- 点击开始，即表示您同意隐私政策 -->
        <div class="row width-100 rember_fill">
          <el-checkbox v-model="checked"></el-checkbox>
          <div class="rember_fill_text">
            By clicking get syarted,you agree to the
            <span class="main-color pointer" @click="navTo('/protocol')">Privacy Policy</span>
          </div>
        </div>
        <!-- 注册按钮 -->
        <div class="login_button">
          <el-button type="primary" :disabled="!checked" @click="loginClick">LOGIN</el-button>
        </div>
        <!-- 分割文字 -->
        <div class="width-100 space-between separate_text">
          <div></div>
          <div>OR</div>
          <div></div>
        </div>
        <!-- 谷歌登录 -->
        <div class="width-100 flex-center guge-button">
          <div class="row pointer g_id_signin" id="g_id_signin">
            <!-- <img src="../../assets/login-6.png" alt="" />
            <span>谷歌</span> -->
          </div>
        </div>
      </div>
      <!-- 右侧三个样式图片 -->
      <img src="../../assets/login-2.png" class="login_img_two posi-abs" alt="" />
      <img src="../../assets/login-3.png" class="login_img_three posi-abs" alt="" />
      <img src="../../assets/login-4.png" class="login_img_four posi-abs" alt="" />
    </div>
  </div>
</template>

<script>
import api from "../../api/index";
export default {
  components: {},

  data() {
    return {
      input: "",
      checked: false,
      query: {
        nickname: "",
        email: "",
        password: "",
        repwd: "",
        invite_code: "",
        code: "",
      },
      second: "Get Pin",
      showText: true,
      GOOGLE_CLIENT_ID: ''
    };
  },
  destroyed() {
    window.removeEventListener("load",this.googleClick);
  },
  created() {
    this.GOOGLE_CLIENT_ID = JSON.parse(localStorage.getItem('siteObj')).google.client_id

    // 使用谷歌登录的api
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client"
    document.body.appendChild(script);
    window.addEventListener('load', this.googleClick)
  },

  mounted() { },

  methods: {
    googleClick() {
      window.google.accounts.id.initialize({
        // 主要就是填写client_id
        client_id: this.GOOGLE_CLIENT_ID,
        auto_select: false,
        callback: this.handleCredentialResponse,
      });
      // 设置按钮的样式等
      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        {
          theme: "filled_blue",
          size: 'large',
          // width:'320',
          type: 'standard',
          text: 'signin_with'
        }
      );
    },
    handleCredentialResponse(response) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      let info = jwt_decode(response.credential)
      info.credential = response.credential
      api.gooleLogin(info).then(res => {
        window.location.href = res.data.url
      })
    },
    loginClick() {
      if (this.query.nickname.trim() == "") {
        this.$message.error("Please enter a nickname");
        return;
      } else if (!/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(this.query.email)) {
        this.$message.error("Please enter the correct email address");
        return;
      } else if (this.query.code.trim() == "") {
        this.$message.error("Please enter the verification code");
        return;
      } else if (this.query.password.trim() == "") {
        this.$message.error("Please enter your password");
        return;
      } else if (this.query.repwd.trim() == "") {
        this.$message.error("Please repeat your password");
        return;
      } else if (this.query.password.trim() != this.query.repwd.trim()) {
        this.$message.error("The two passwords are different");
        return;
      }
      api.getRegister(this.query).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "Registered successfully",
            type: "success",
          });
          setTimeout(() => {
            this.navTo("/login");
          }, 1000);
        }
      });
    },
    // 跳转页面
    navTo(url) {
      this.$router.push(url);
    },
    codeClick() {
      if (!/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(this.query.email)) {
        this.$message.error("Please enter the correct email address");
        return;
      }
      if (this.showText == false) {
        this.$message({
          message: "Please do not click repeatedly",
          type: "warning",
        });
        return false;
      } else {
        setTimeout(() => {
          this.codeSend();
        }, 1000);
      }
    },
    // 发送接口
    codeSend() {
      let query = {
        email: this.query.email,
        type: 1,
      };
      api.getEmail(query).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.codeCountdown();
        }
      });
    },
    // 验证码倒计时
    codeCountdown() {
      this.showText = false;
      let time = 60;
      if (time > 0) {
        var a = setInterval(() => {
          time = time - 1;
          this.second = time + "s";
          if (time == 0) {
            clearInterval(a);
            this.showText = true;
            this.second = "Get Pin";
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.portal_login {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/login.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .login_center {
    width: 1226px;
    height: 820px;
    margin: 0 auto;
    background: #fff;
    background-image: url("../../assets/login-1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 16px 0 32px 54px;

    .login_center_left {
      width: 388px;
      height: 100%;
      font-size: 14px;
      color: #333333;

      .title_img {
        width: 266px;
        height: 48px;
        margin-bottom: 30px;
      }

      .title_text {
        color: #999999;
        margin-bottom: 12px;
      }

      .login_fill {
        margin-bottom: 16px;

        .login_fill_input {
          // margin-top: 2px;

          /deep/.el-input__inner:focus {
            border-color: #ffebd8;
          }
        }

        .login_fill_input_one {
          width: 290px;
        }

        .login_pin {
          width: 98px;
          height: 40px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          color: #999999;
        }

        .login_pin:hover {
          border-color: #ffebd8;
          color: #fb9a67;
        }
      }

      .rember_fill {
        margin-top: 36px;

        .forget_password {
          color: #ff652b;
        }

        /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
          color: #ff652b;
        }

        /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #ff652b;
          border-color: #ff652b;
        }

        /deep/.el-checkbox__inner:hover {
          border-color: #fb9a67;
        }

        /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
          border-color: #fb9a67;
        }

        .rember_fill_text {
          margin-left: 3px;
        }
      }

      .login_button {
        margin: 48px 0;

        /deep/.el-button {
          width: 100%;
          height: 45px;
          font-size: 24px;
          padding: 0;
          text-align: center;
          line-height: 45px;
        }

        /deep/.el-button--primary.is-disabled {
          background: rgba(255, 101, 43, 0.2);
          border: none;
        }

        /deep/.el-button--primary {
          background-color: #ff652b;
          border-color: #ff652b;
        }
      }

      .back_login {
        font-size: 14px;
        margin-bottom: 48px;

        i {
          display: inline-block;
          position: relative;
          top: 2px;
          font-size: 15px;
        }
      }

      .separate_text {
        font-size: 16px;

        div:first-child,
        div:last-child {
          width: 160px;
          height: 0px;
          opacity: 0.3;
          border-bottom: 1px solid #707070;
        }
      }

      .guge-button {
        margin-top: 35px;
        // div {
        //   width: 187px;
        //   height: 47px;
        //   background: #ffffff;
        //   border-radius: 4px;
        //   border: 1px solid #707070;
        //   padding-left: 18px;
        //   font-size: 16px;
        //   img {
        //     width: 47px;
        //     height: 100%;
        //     margin-right: 13px;
        //   }
        // }
        // div:hover {
        //   border: 1px solid #ffebd8;
        // }
      }
    }

    .login_img_two {
      top: 187px;
      right: 250px;
      width: 307px;
      height: 347px;
    }

    .login_img_three {
      top: 252px;
      right: 498px;
      width: 55px;
      height: 102px;
      z-index: 2;
    }

    .login_img_four {
      top: 378px;
      right: 181px;
      width: 75px;
      height: 111px;
      z-index: 2;
    }
  }
}
</style>
